import React, { useState } from 'react'

import { TrailFadeUp } from '@utils/animations'
import {
  ArticleGrid,
  ProjectSuperscription,
  ProjectTitle,
  ProjectImage,
  StyledContainerBox,
  ProjectLink,
  DateBox,
  ProjectSummary,
  ProjectLinkInner,
} from './style'
import { Box, Flex } from '@rebass/grid'

export type ArticleListPrimaryProps = {
  posts: any
  loadMoreLabel: string
}

export const ArticleListSlice: React.FC<ArticleListPrimaryProps> = ({
  posts,
  loadMoreLabel,
}) => {
  // TODO move chunkSize to prismic and set 3 as default/fallback value
  const chunkSize = 6
  const [renderedPosts, setVisiblePosts] = useState<PrismicNode<PostSummary>[]>(
    posts?.slice(0, Math.min(posts.length, chunkSize)) || [],
  )
  const loadMore = () => {
    setVisiblePosts(
      posts.slice(0, Math.min(posts.length, renderedPosts.length + chunkSize)),
    )
  }
  return (
    <StyledContainerBox>
      <ArticleGrid>
        {renderedPosts?.map((post, index) => {
          const node = post?.node
          const nodeData = node?.data
          const cover = nodeData?.cover

          return (
            node && (
              <TrailFadeUp key={index} delay={(index % 3) * 250}>
                <ProjectLink to={node?.url}>
                  <Flex>
                    {cover?.gatsbyImageData && (
                      <ProjectLinkInner>
                        <ProjectImage
                          image={cover?.gatsbyImageData}
                          alt={cover?.alt || 'cover image'}
                        />
                      </ProjectLinkInner>
                    )}
                    <DateBox>{nodeData?.date}</DateBox>
                  </Flex>
                  <ProjectSummary>
                    <ProjectTitle>{nodeData?.title?.text}</ProjectTitle>
                    <ProjectSuperscription>
                      {nodeData?.superscription}
                    </ProjectSuperscription>
                  </ProjectSummary>
                </ProjectLink>
              </TrailFadeUp>
            )
          )
        })}
      </ArticleGrid>
      {posts.length !== renderedPosts.length && (
        <button onClick={loadMore}>{loadMoreLabel}</button>
      )}
    </StyledContainerBox>
  )
}
