import Link from 'gatsby-link'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'

import { rem } from '@styles/02-tools/tools.common'
import { fontWeights, breakpoints, paletteText } from '@styles/01-settings'

import { bp } from '@styles/02-tools/tools.breakpoints'
import { ContainerBox } from '@components/container'
import { Box, Flex } from '@rebass/grid'

export const StyledContainerBox = styled(ContainerBox)`
  background-color: ${paletteText.secondary_background};
`

export const ArticleGrid = styled.section`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: ${rem(80)}rem ${rem(60)}rem;
  margin-bottom: ${rem(40)}rem;

  ${bp(breakpoints.sm, `grid-template-columns: repeat(2, 1fr);`)}
  ${bp(breakpoints.lg, `grid-template-columns: repeat(3, 1fr);`)}
`

export const ProjectImage = styled(GatsbyImage)`
  max-height: 260px;
`

export const ProjectSuperscription = styled.div`
  color: ${paletteText.secondary_text};
`

export const ProjectTitle = styled.h3`
  margin-bottom: 0;
`

export const ProjectSummary = styled(Flex)`
  align-items: center;
  justify-content: space-between;
`

export const ProjectLinkInner = styled.span`
  display: block;
`

export const ProjectLink = styled(Link)`
  color: ${paletteText.secondary_text};
  cursor: pointer;
  font-size: ${rem(14)}rem;
  line-height: ${rem(19)}rem;

  ${ProjectLinkInner} {
    border-radius: 0;
    overflow: hidden;

    img {
      transition: all 0.2s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
`

export const StyledButton = styled.button`
  display: block;
  margin: 0 auto;
  margin-bottom: ${rem(80)}rem;
  max-width: ${rem(200)}rem;
`

export const DateBox = styled(Box)`
  font-size: ${rem(14)}rem;
  margin-left: 0.5rem;
  writing-mode: vertical-rl;
`
