import React from 'react'
import { graphql } from 'gatsby'
import { Layout } from '@components/layout'
import { Slice } from '@components/slice'
import ArticleListSlice from '@slices/article-list'

type Props = {
  data: any
  pageContext: any
}

const ProjectsIndex: React.FC<Props> = props => {
  const metaData = props.data?.meta_data
  const seo = props.data?.seo
  let header = props.data?.header
  const main = props.data?.main?.data
  const footer = props.data?.footer
  const theme = props.pageContext?.theme
  const slices = main?.body

  const posts = props?.data?.posts
  header = { ...header, socialLinks: footer?.data?.social_links }

  return (
    <Layout
      seo={seo}
      header={header}
      footer={footer}
      theme={theme}
      activeMetaData={metaData}
    >
      <Slice allSlices={slices} />
      <ArticleListSlice
        posts={posts.edges.map(post => post)}
        loadMoreLabel={props?.data?.pagination?.data?.load_more_text}
      ></ArticleListSlice>
    </Layout>
  )
}

export default ProjectsIndex

export const pageQuery = graphql`
  query ProjectsIndexPage($locale: String) {
    seo: prismicProjectsIndex(lang: { eq: $locale }) {
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url(imgixParams: { width: 1200, height: 630 })
        }
      }
    }
    header: prismicHeader(lang: { eq: $locale }) {
      ...header
    }
    main: prismicProjectsIndex(lang: { eq: $locale }) {
      data {
        body {
          ... on PrismicProjectsIndexDataBodyHeadline {
            ...headline
          }
        }
      }
    }
    pagination: prismicProjectsIndex {
      data {
        load_more_text
      }
    }
    posts: allPrismicProjectPost(
      sort: { fields: [data___date], order: DESC }
      filter: { lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          uid
          url
          data {
            title {
              text
            }
            superscription
            date(formatString: "MM/YYYY")
            cover {
              gatsbyImageData(placeholder: NONE)
            }
          }
        }
      }
    }
    footer: prismicFooter(lang: { eq: $locale }) {
      ...footer
    }
  }
`
